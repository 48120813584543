/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onProgressUpdate = /* GraphQL */ `
  subscription OnProgressUpdate($doc_id: Int!) {
    onProgressUpdate(doc_id: $doc_id) {
      details
      doc_id
      status
      __typename
    }
  }
`;
