import Amplify, { API, graphqlOperation } from "aws-amplify";
import { getDocumentEstimateData, applyWorkOrderTemplate, getDocumentWorkOrderData, createDocWithTemplate} from '../../graphql/queries';
import { onProgressUpdate }  from '../../graphql/subscriptions';
import {consoleLog} from '../commonFunctions.js';
import config from "../../aws-exports";
Amplify.configure(config);

export const isEmpty = (value) => { 
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }
  return false;
}

export const parseNumber = (value) => { 
  if (isNaN(Number.parseFloat(value))) {
    return 0;
  }
  return Number.parseFloat(value);
}

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4]
    };
    
    //Check if the input is of correct length
    let match2 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match2) {
      return '(' + match2[1] + ') ' + match2[2] + '-' + match2[3]
    };

    return str
};

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,      
  maximumFractionDigits: 2,
});

export const calculateAdjQuantity = (line_item) => {
  let adjusted_quantityx = +line_item.quantity+(line_item.quantity*line_item.waste/100);
  adjusted_quantityx = Number.parseFloat(adjusted_quantityx).toFixed(2);
  // console.log('calculateAdjQuantity', adjusted_quantityx);
  // console.log('calculateAdjQuantity typeof', typeof adjusted_quantityx);
  return adjusted_quantityx;
}

export const calculateNetCost = (line_item) => {
  let net_cost;
  let adjusted_quantity = calculateAdjQuantity(line_item);
  if(adjusted_quantity == 0){
      net_cost = line_item.cost * line_item.quantity;
  }else{
      net_cost = line_item.cost * adjusted_quantity;
  }
  // net_cost = formatter.format(net_cost);
  console.log('calculateNetCost', net_cost);
  console.log('calculateNetCost typeof', typeof net_cost);
  net_cost = parseFloat(net_cost);
  return net_cost.toFixed(2);
}

const calculateNetCostx = (line_item) => {
  let net_cost = 0;
  let adjusted_quantity = calculateAdjQuantity(line_item);
  if(adjusted_quantity == 0){
      net_cost = line_item.cost * line_item.quantity;
  }else{
      net_cost = line_item.cost * adjusted_quantity;
  }
  net_cost = Number.parseFloat(net_cost);
  return net_cost;
}

const adjusted_quantity_calculation = (props) => {
  let ratio_fromx = props.ratio_from ? props.ratio_from : 1;
    let ratio_tox = props.ratio_to ? props.ratio_to : 1;
    let quantityx = 1 * props.quantity;
    let wastex = 1 * props.waste;
    let convert_roundx = 1 * props.convert_round;
    let quant = quantityx * ratio_tox / ratio_fromx;
    let adjusted_quantityx = ((100 + wastex) * quant) / 100;
    if (convert_roundx) {
        adjusted_quantityx = Math.ceil(adjusted_quantityx);
        adjusted_quantityx = adjusted_quantityx.toFixed(2);
        adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    } else {
        adjusted_quantityx = Math.round(adjusted_quantityx * 100) / 100;
        adjusted_quantityx = adjusted_quantityx.toFixed(2);
        adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    }
    return adjusted_quantityx
}

export const calculateDataWO = (data) => {

  let subTotal = 0;
  let matCost = 0;
  let oNpCost = 0;
  let totalQuantity = 0;

  if(data && data.structures) {
    for (const structure of data.structures) {
      for (const category of structure.categories) {
        for (const lineItem of category.line_items) {
          let netCost = lineItem.cost * adjusted_quantity_calculation(lineItem)
          let qty = calculateAdjQuantity(lineItem);
          if(lineItem.unit != ''){
            subTotal = subTotal + 1 * netCost;
          
            totalQuantity = totalQuantity + 1 * qty;
            matCost = matCost + 1 * filterNumber(lineItem.material_cost) * (1 * qty);

            if (parseInt(lineItem.apply_op) === 1) {
              oNpCost = oNpCost + 1 * netCost;
            }
          }
        }
      }
    }
  }

  subTotal = Number.parseFloat(subTotal).toFixed(2);
  matCost = Number.parseFloat(matCost);
  oNpCost = Number.parseFloat(oNpCost);

  let calculatedData = getMarkupsAndTaxesCalculation(data, subTotal, matCost, oNpCost);

  return {...calculatedData, totalQuantity: totalQuantity}
}


export const calculateData = (data) => {

  let subTotal = 0;
  let matCost = 0;
  let oNpCost = 0;
  let totalQuantity = 0;

  if(data && data.structures) {
    for (const structure of data.structures) {
      for (const category of structure.categories) {
        if(category?.line_items == undefined){
          category.line_items = [];
        }
        for (const lineItem of category.line_items) {
          // let netCost = filterNumber(lineItem.net_cost);
          let netCost = calculateNetCostx(lineItem);
          // console.log('calculateData netCost', netCost);
          // let qty = filterNumber(lineItem.adjusted_quantity);
          let qty = calculateAdjQuantity(lineItem);
          // console.log('calculateData qty', qty);
          if(lineItem.unit != ''){
            subTotal = subTotal + 1 * netCost;
          
            totalQuantity = totalQuantity + 1 * qty;
            matCost = matCost + (1 * filterNumber(lineItem.material_cost) * qty);

            if (parseInt(lineItem.apply_op) === 1) {
              oNpCost = oNpCost + 1 * netCost;
            }
          }
        }
      }
    }
  }

  subTotal = Number.parseFloat(subTotal).toFixed(2);
  matCost = Number.parseFloat(matCost);
  oNpCost = Number.parseFloat(oNpCost);

  let calculatedData = getMarkupsAndTaxesCalculation(data, subTotal, matCost, oNpCost);

  return {...calculatedData, totalQuantity: totalQuantity}
}

const getMarkupsAndTaxesCalculation = (data, subTotal, matCostTotal, oNpCostTotal) => {
  
  let markups = data.markups;

  if(typeof markups === 'string') {
    markups = JSON.parse(data.markups);
  }else {
    markups = data.markups;
  }

  let taxesArray = [];
  let markupArray = [];
  let totalMarkup = 0;
  let totalOPMarkup = 0;
  let totalMatCost = 0;
  let totalCostPercentage = 0;
  if (markups && typeof markups === 'object' && Object.keys(markups).length > 0) {

    Object.entries(markups).forEach(([key, markup]) => {
      if (markup.type === "markup" || markup.type === "op") {
        console.log("getMarkupsAndTaxesCalculation ", markup);
        //calculations for total markup
        if (markup.type === "markup") {
          totalMarkup = totalMarkup + 1 * markup.value;
          let total = subTotal * (markup.value / 100);
          markup.total = total.toFixed(2);
        }

        //calculations for total OP markup
        if (markup.type === "op") {
          totalOPMarkup = totalOPMarkup + 1 * markup.value;
          let totalOp = oNpCostTotal * (markup.value / 100);
          markup.total = totalOp.toFixed(2);
        }

        markupArray.push(markup);
        
      }else if (markup.type === "tax") {

        //calculations for material cost
        if (markup.for === "material") {
          totalMatCost = totalMatCost + 1 * markup.value; // ading the percentages to calculate material cost
          let matCost = matCostTotal * (markup.value / 100);
          markup.total = matCost.toFixed(2);
        }

        //calculations for total cost
        if (markup.for === "all") {
          let percentageOne = subTotal * ((1 * markup.percent) / 100);
          let percentageTwo = percentageOne * ((1 * markup.value) / 100);
          let percentage = percentageTwo.toFixed(2);
          totalCostPercentage += parseFloat(percentage);
          markup.total = percentage;
        }
        taxesArray.push(markup);
      }
    });
  }
  let subTotalPercentage = subTotal * (totalMarkup / 100);
  subTotalPercentage = Number.parseFloat(subTotalPercentage).toFixed(2);
  
  let oNpCostTotalPercentage = oNpCostTotal * (totalOPMarkup / 100);
  oNpCostTotalPercentage = Number.parseFloat(oNpCostTotalPercentage).toFixed(2);
  
  let totalMatCostPercentage = matCostTotal * (totalMatCost / 100);

  totalMatCostPercentage = Number.parseFloat(totalMatCostPercentage).toFixed(2);
  
  let total =
        Number.parseFloat(subTotal) +
        Number.parseFloat(subTotalPercentage) +
        Number.parseFloat(oNpCostTotalPercentage) +
        Number.parseFloat(totalMatCostPercentage) +
        Number.parseFloat(totalCostPercentage);

  return {total: total.toFixed(2), subTotal: subTotal, taxes: taxesArray, markups: markupArray, totalMarkup: subTotalPercentage, totalOpMarkup: oNpCostTotalPercentage, totalMaterialCost: totalMatCostPercentage, totalCost: Number.parseFloat(totalCostPercentage).toFixed(2)}
}

const filterNumber = (number) => {

  if(typeof number === 'undefined' || isNaN(number)) {
      return 0;
  }else {
      return number;
  }
}
export const getSantizedSublineItem = (sublineItem) => {
  console.log("getSantizedSublineItem input:", sublineItem);

  try {
    if (Array.isArray(sublineItem)) {
      sublineItem = sublineItem.map(item => 
        String(item).trim().replace(/(\r\n|\n|\r)/gm, "")
      );
    } else if (typeof sublineItem === "string") {
      try {
        // Try parsing as JSON array first
        sublineItem = JSON.parse(sublineItem);
        if (!Array.isArray(sublineItem)) throw new Error();
      } catch {
        // Fallback: Remove outer brackets & split manually
        sublineItem = sublineItem
          .replace(/^\[|\]$/g, "") // Remove surrounding brackets
          .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/) // Split, but keep commas inside quotes
          .map(item => item.trim().replace(/^["']|["']$/g, ""));
      }
    } else {
      throw new Error("Invalid input format");
    }

    // Final cleanup & filtering
    sublineItem = sublineItem.filter(e => e.trim() !== "");

    console.log("getSantizedSublineItem output:", sublineItem);
    return sublineItem;
  } catch (error) {
    console.error("Error in getSantizedSublineItem:", error);
    return [];
  }
};

export const calculateFinanceAmount = (percentage, total, months) => {
  const monthlyRate = (percentage / 100) / 12;
  const factor = Math.pow(1 + monthlyRate, months);
  let monthlyPayment = (total * monthlyRate * factor) / (factor - 1);
  const totalRepayment = monthlyPayment * months;
  let totalInterestPaid = totalRepayment - total;
  monthlyPayment = monthlyPayment.toFixed(2);
  return !isNaN(monthlyPayment)?monthlyPayment:0;
};

export const subscribeToProgress = async (doc_id) => {
  console.log("subscribeToProgress function called.");

  return new Promise((resolve, reject) => {
      const subscription = API.graphql(
          graphqlOperation(onProgressUpdate, { doc_id })
      ).subscribe({
          next: async ({ value }) => {
              console.log('subscribeToProgress Progress update:', value.data.onProgressUpdate);
              const fileUrl = value.data.onProgressUpdate.details;

              try {
                  const response = await fetch(fileUrl);
                  
                  if (!response.ok) {
                      throw new Error("Failed to fetch JSON from S3");
                  }

                  const contentType = response.headers.get("Content-Type");
                  if (!contentType || !contentType.includes("application/json")) {
                      throw new Error("The fetched file is not JSON");
                  }

                  const rawResponse = await response.text();
                  console.log("Raw Response Body:", rawResponse);

                  const jsonData = JSON.parse(rawResponse);
                  consoleLog("jsonData ===>",jsonData);
                  resolve(jsonData);
              } catch (error) {
                  console.error("Error fetching JSON from S3:", error);
                  reject(error);
              } finally {
                  subscription.unsubscribe();
              }
          },
          error: (error) => {
              console.error(error);
              reject(error); // Reject the promise on error
          },
      });
  });
};

export const apiCallWithSubscription = async (variables, queryName) => {
  try {
      const queryMap = {
          createDocWithTemplate,
          getDocumentEstimateData,
          getDocumentWorkOrderData,
          applyWorkOrderTemplate
      };
      const query = queryMap[queryName];
      if (!query) return 0;
      const apiResponse = await API.graphql({ query, variables });
      const subscriptionData = await subscribeToProgress(variables.doc_id);
      return subscriptionData;
      
  } catch (error) {
      console.error("API Call with Subscription Error:", error);
      throw error;
  }
};
